import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {marker} from '@jsverse/transloco-keys-manager/marker';
import {AlcNode, ROOT} from '../components/tree/base-tree/base-tree.interface';
import {Translate} from './translate.service';

export class ArrayService {
  private static scrollTimeout: number = 0;

  static objectToArray(object: {}, keepKey?: boolean, parseIntKey?: boolean): any[] {
    const keys = Object.keys(object);
    const array = [];
    const n = keys.length;
    for (let i = 0; i < n; i++) {
      const key = parseIntKey ? parseInt(keys[i], 10) : keys[i];
      const value = object[key];
      if (keepKey) {
        if (isObject(value)) {
          value.id = key;
          array.push(value);
        } else {
          array.push({
            id: key,
            value
          });
        }
      } else {
        array.push(value);
      }
    }
    return array;
  }

  static arrayToObject<T>(array: T[], id = 'id'): Record<number, T> {
    const object: Record<number, T> = {};
    let arrayIndex = array.length - 1;
    for (; arrayIndex >= 0; arrayIndex--) {
      object[array[arrayIndex][id]] = array[arrayIndex];
    }
    return object;
  }

  static findItemInTree(tree, matchValue, childrenProperty: string = 'children', property: string = 'id') {
    if (tree[property] === matchValue) {
      return tree;
    }

    const children = tree[childrenProperty];
    let result;
    if (children) {
      for (let i = children.length - 1; i > -1; i--) {
        result = ArrayService.findItemInTree(children[i], matchValue, childrenProperty, property);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }

  static treeToFlatArray(tree) {
    const flatArray = [];
    if (Array.isArray(tree)) {
      let treeIndex = tree.length - 1;
      for (; treeIndex >= 0; treeIndex--) {
        ArrayService.treeToFlatArrayRecursive(tree[treeIndex], flatArray);
      }
    } else if (typeof tree === 'object') {
      ArrayService.treeToFlatArrayRecursive(tree, flatArray);
    }
    return flatArray;
  }

  static scrollToSelected<T>(array: T[], selected: number | string | ((item: T) => boolean), scroll: CdkVirtualScrollViewport): void {
    if (!scroll || !array) {
      return;
    }

    clearTimeout(this.scrollTimeout);

    this.scrollTimeout = setTimeout(() => {
      const selectedIndex = array.findIndex(item => (typeof selected === 'function' ? selected(item) : (item as any).id === selected));
      if (selectedIndex > -1) {
        const viewPortSize = scroll.getViewportSize();
        const renderedRange = scroll.getRenderedRange();
        const itemSize = scroll.measureRenderedContentSize() / (renderedRange.end - renderedRange.start);
        const scrollOffset = scroll.measureScrollOffset() - renderedRange.start * itemSize;
        const selectedScrollTop = itemSize * (selectedIndex - renderedRange.start);
        const isNotVisible = selectedScrollTop < scrollOffset || scrollOffset + viewPortSize + 1 < selectedScrollTop + itemSize;
        const isNotInRange = selectedIndex < renderedRange.start || renderedRange.end < selectedIndex;

        if (isNotInRange || isNotVisible) {
          scroll.scrollToIndex(selectedIndex, 'smooth');
        }
      }
    }, 800);
  }

  static arrayObjectsToIdsArray(array, idProperty) {
    const newArray = [];
    const length = array.length;
    let index = 0;
    while (index < length) {
      newArray.push(array[index][idProperty]);
      index++;
    }

    return newArray;
  }

  private static treeToFlatArrayRecursive(tree, flatArray) {
    flatArray.push(tree);
    if (tree.children && tree.children.length) {
      let childrenIndex = tree.children.length - 1;
      for (; childrenIndex >= 0; childrenIndex--) {
        ArrayService.treeToFlatArrayRecursive(tree.children[childrenIndex], flatArray);
      }
    }
  }

  static arrayToTree(data: any[], rootLabel?: string, makeNodeAsRootOnlyWhenParentIdIsNull = true): AlcNode {
    return ArrayService.objectToTree(ArrayService.arrayToObject(data), rootLabel, makeNodeAsRootOnlyWhenParentIdIsNull);
  }

  private static objectToTree(dataMap: any, rootLabel?: string, makeNodeAsRootOnlyWhenParentIdIsNull = true): AlcNode {
    const rootNodes: AlcNode[] = [];
    const ids = Object.keys(dataMap);
    for (const id of ids) {
      const node = dataMap[id];
      node.children = node.children || [];
      if (node.parentId && dataMap[node.parentId]) {
        this.addNodeToParent(dataMap, node);
      } else if (!makeNodeAsRootOnlyWhenParentIdIsNull || !node.parentId) {
        // add to `rootNodes` only those nodes that have don't have a parentId because there is the use case when you have a node that have a parentId
        // but the parent node cannot be found in the flat list (e.g. in alc-group-list-data-point-filters when a `level 1` node is selected, the
        // tree shouldn't move the `level2` nodes to root nodes)
        node.parentId = null;
        rootNodes.push(node);
      }
    }

    if (rootNodes.length === 1) {
      return rootNodes[0];
    }

    return {
      id: ROOT,
      parentId: null,
      name: rootLabel || Translate.instant(marker('GENERAL.ALL')),
      children: rootNodes
    };
  }

  private static addNodeToParent(dataMap: any, node) {
    const parent = dataMap[node.parentId];
    parent.children = parent.children || [];
    if (parent.children.indexOf(node) === -1) {
      parent.children.push(node);
    }
  }
}

export const remove = <T>(array: T[], element: number | string | object | ((item: T) => boolean)): T[] => {
  if (!array?.length) {
    return array;
  }

  const removedItems = [];
  const length = array.length;
  if (typeof element === 'function') {
    for (let i = length - 1; i > -1; i--) {
      const item = array[i];
      if (element(item)) {
        removedItems.push(item);
        array.splice(i, 1);
      }
    }
  } else if (typeof element === 'object') {
    for (let i = length - 1; i > -1; i--) {
      const item = array[i];
      if (matchesObjectProperties(element, item)) {
        removedItems.push(item);
        array.splice(i, 1);
      }
    }
  } else {
    for (let i = length - 1; i > -1; i--) {
      const item = array[i];
      if (element === item) {
        removedItems.push(item);
        array.splice(i, 1);
      }
    }
  }

  return removedItems.reverse();
};

const matchesObjectProperties = <T>(object: object, item: T): boolean => {
  const objectKeys = Object.keys(object || {});
  for (const key of objectKeys) {
    if (object[key] !== item[key]) {
      return false;
    }
  }
  return true;
};

export const findBy = <T>(array: T[], element: object): T | undefined => array.find(item => matchesObjectProperties(element, item));

export const filterBy = <T>(array: T[], element: object): T[] => array.filter(item => matchesObjectProperties(element, item));

export const intersection = <T>(array1: T[], array2: T[]): T[] => array1.filter(a => array2.includes(a));

export const intersectionBy = <T>(array1: T[], array2: T[], key: string): T[] => array1.filter(a => array2.some(b => b[key] === a[key]));

export const difference = <T>(array1: T[], array2: T[]): T[] => array1.filter(a => !array2?.some(b => b === a));

export const differenceBy = <T>(array1: T[], array2: T[], key: string): T[] => array1.filter(a => !array2?.some(b => b[key] === a[key]));

type differenceWith = {
  <T1, T2>(array1: T1[], array2: T2[], check: (a: T1, b: T2) => boolean): T1[];
  <T>(array1: T[], array2: T[], check: (a: T, b: T) => boolean): T[];
};
export const differenceWith: differenceWith = <T>(array1: T[], array2: T[], check: (a: T, b: T) => boolean): T[] =>
  array1.filter(a => !array2?.some(b => check(a, b)));

export const uniq = <T>(array: T[]): T[] => array.filter((a, index) => index === array.findIndex(b => b === a));

export const uniqBy = <T>(array: T[], key: string): T[] => array.filter((a, index) => index === array.findIndex(b => b[key] === a[key]));

export const uniqWith = <T>(array: T[], check: (a: T, b: T) => boolean): T[] =>
  array.filter((a, index) => index === array.findIndex(b => check(a, b)));

export const unionBy = <T>(array: T[], array2: T[], key: string): T[] => uniqBy(array.concat(array2), key);
export const groupBy = <T>(array: T[], key: string): Record<string, T[]> =>
  array.reduce((r, v, i, a, k = v[key]) => ((r[k] || (r[k] = [])).push(v), r), {});

export const isObject = (value: any): boolean => typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isObjectLike = (value: any): boolean => typeof value === 'object' && value !== null;

/**
 * Converts an array of bits (booleans) to the decimal integer value they represent.
 * Examples:
 * - [false, true, false, false] => 4
 * - [true, false, true, false] => 10
 */
export const bitArrayToNumber = (array: boolean[]): number => (array as any[]).reduce((result: number, bit: any): number => (result << 1) | bit);

type isDeepEqual = {
  <T1, T2>(target: T1, source: T2, stack?: Map<T2, T1>): boolean;
  <T>(target: T, source: T, stack?: Map<T, T>): boolean;
};
// eslint-disable-next-line complexity
const isDeepEqual: isDeepEqual = (target, source, stack = new Map()): boolean => {
  if (target === source) {
    return true;
  }

  if (target === null || source === null || target === undefined || source === undefined || (!isObjectLike(target) && !isObjectLike(source))) {
    return target === source;
  }

  // compare primitives
  if (target !== Object(target) && source !== Object(source)) {
    return target === source;
  }

  const keysA = Object.keys(target);
  const keysB = Object.keys(source);
  if (keysA.length !== keysB.length) {
    return false;
  }

  const typeA = isObject(target);
  const typeB = isObject(source);
  if (typeA !== typeB) {
    // check if e.g. target is object and source is array
    return false;
  }

  // compare objects with same number of keys
  for (const key of keysA) {
    if (!source.hasOwnProperty(key)) {
      return false; // source object doesn't have this prop
    }
    if (isObject(target[key])) {
      if (stack.has(source[key])) {
        return true; // if it's circular reference make them as they are true
      }
      stack.set(source[key], target[key]);
    }
    if (!isDeepEqual(target[key], source[key], stack)) {
      return false;
    }
  }
  return true;
};

type isEqual = {
  <T1, T2>(target: T1, ...sources: T2[]): boolean;
  <T>(target: T, ...sources: T[]): boolean;
};

export const isEqual: isEqual = <T>(target: T, ...sources: T[]): boolean => {
  for (const source of sources) {
    if (!isDeepEqual(target, source)) {
      return false;
    }
  }
  return true;
};

type deepMerge = {
  <T1, T2>(target: T1, source: T2, stack?: Map<T2, T1>): T1 & T2;
  <T>(target: T, source: T, stack?: Map<T, T>): T;
};
const deepMerge: deepMerge = (target, source, stack = new Map()) => {
  const keysB = Object.keys(source);
  // Loop over the keys of source
  for (const key of keysB) {
    // Check if both values are objects like
    const srcValue = source[key];
    let targetValue = target[key];
    if (isObjectLike(srcValue) && !(srcValue instanceof HTMLElement)) {
      if (!isObjectLike(targetValue)) {
        // separate checks for target so circular reference objects are not assigned
        // by referenced so new objects are created to receive the properties
        if (Array.isArray(srcValue)) {
          targetValue = target[key] = [];
        } else {
          targetValue = target[key] = {};
        }
      }
      if (stack.has(srcValue)) {
        targetValue = target[key] = stack.get(srcValue);
      } else {
        stack.set(srcValue, targetValue);
        // Call deepMerge recursively on both values
        deepMerge(targetValue, srcValue, stack);
      }
    } else if (srcValue !== undefined || !target.hasOwnProperty(key)) {
      // Assign value from source to target
      target[key] = srcValue;
    }
  }
  // Return merged object
  return target;
};

type merge = {
  <T1, T2>(target: T1, ...sources: T2[]): T1 & T2;
  <T>(target: T, ...sources: T[]): T;
};
export const merge: merge = <T>(target: T, ...sources: T[]): T => {
  sources.forEach(source => deepMerge(target, source));
  return target;
};

type customizer = <T1, T2, T4, T5>(
  targetValue: T1,
  srcValue: T2,
  key: string,
  target: T4,
  source: T5,
  stack: Map<T2, T1>
) => (T1 & T2) | T1 | T2 | undefined;
type deepMergeWith = {
  <T1, T2>(customizer: customizer, target: T1, source: T2, stack?: Map<T2, T1>): T1 & T2;
  <T>(customizer: customizer, target: T, source: T, stack?: Map<T, T>): T;
};
// eslint-disable-next-line complexity
const deepMergeWith: deepMergeWith = <T>(customizer: customizer, target: T, source: T, stack = new Map()): T => {
  const keysB = Object.keys(source);
  // Loop over the keys of source
  for (const key of keysB) {
    // Check if both values are objects like
    let srcValue = source[key];
    let targetValue = target[key];
    srcValue = customizer(targetValue, srcValue, key, target, source, stack);
    if (srcValue === undefined) {
      srcValue = source[key];
    }
    if (isObject(srcValue)) {
      if (!isObject(targetValue)) {
        // separate checks for target so circular reference objects are not assigned
        // by referenced so new objects are created to receive the properties
        if (Array.isArray(srcValue)) {
          targetValue = target[key] = [];
        } else {
          targetValue = target[key] = {};
        }
      }
      if (stack.has(srcValue)) {
        targetValue = target[key] = stack.get(srcValue); // Cyclic reference else
      } else {
        stack.set(srcValue, targetValue);
        // Call deepMerge recursively on both values
        deepMergeWith(customizer, targetValue, srcValue, stack);
      }
    } else if (srcValue !== undefined || !target.hasOwnProperty(key)) {
      // Assign value from source to target
      targetValue = target[key] = srcValue;
    }
  }

  if (Array.isArray(source) && Array.isArray(target) && !source.length) {
    target.splice(0, target.length);
  }

  // Return merged object
  return target;
};

type mergeWith = {
  <T1, T2>(customizer: customizer, target: T1, ...sources: T2[]): T1 & T2;
  <T>(customizer: customizer, target: T, ...sources: T[]): T;
};
export const mergeWith: mergeWith = <T>(customizer: customizer, target: T, ...sources: T[]): T => {
  sources.forEach(source => deepMergeWith(customizer, target, source));
  return target;
};

export const minWith = <T>(array: T[], check: (item: T) => any): T => array.reduce((a: T, b: T) => (check(a) <= check(b) ? a : b));
export const maxWith = <T>(array: T[], check: (item: T) => any): T => array.reduce((a: T, b: T) => (check(a) >= check(b) ? a : b));
export const isNil = (value: any): boolean => value === undefined || value === null;
export const preventKeyValueSorting = () => 0;

export const sortBy = (key: string) => (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);

interface DebouncedFunc<T extends (...args: any[]) => any> {
  (...args: Parameters<T>): void;

  cancel(): void;
}

export const debounce = <T extends (...args: any) => any>(func: T, wait: number = 0): DebouncedFunc<T> => {
  if (typeof func !== 'function') {
    throw new TypeError('Expected a function');
  }
  let timeout: number;

  function debounced(): void {
    const context = this;
    const args = arguments;
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  }

  debounced.cancel = () => window.clearTimeout(timeout);
  return debounced;
};

export const throttle = (func, timeFrame: number = 0) => {
  let lastTime = 0;
  return function (...args) {
    const now = Date.now();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
};
