import {DashboardObjectTO, RealEstateDetailsTO, UserDisplayPropertiesTO, UserGroupLabelTO} from 'api/entities';
import {Attendance, RealEstateAlarmChain} from '../../../dashboards/widgets/real-estate-details/real-estate-details.interface';
import {DataPointAndFiltersInfo} from '../data-point-and-filters/data-point-filters.interface';
import {GroupType} from './group-types.constant';

export interface GroupLocation {
  latitude?: number;
  longitude?: number;
  location?: string;
}

export interface Group {
  id?: number;
  name?: string;
  parentId?: number;
  type?: GroupType;
  groupLocation?: GroupLocation;
  children?: Group[];
  parentGroup?: Group;
  collapse?: boolean;
  level?: number;
  levelPadding?: number;
  dashboards?: DashboardObjectTO[];
  groupTagIds?: number[];
}

export interface STATE {
  entityId?: number;
  alarmState: number;
  manualMode: number;
  alarmSuppression: number;
  outOfServiceState: number;
}

interface GroupDetailsBase {
  id?: number;
  location?: GroupLocation;
  name?: string;
  ownerAddressId?: number;
}

export interface GroupDetails extends GroupDetailsBase {
  dataPointFilters?: DataPointAndFiltersInfo[];
  details?: {
    name?: string;
    picture?: string;
    buildingUse?: number;
  };
  documents?: [];
  parentId?: number;
  type?: GroupType;
  userGroups?: UserGroupLabelTO[];
  portfolioBaseYear?: number;
  groupTagIds?: number[];
}

export interface NewGroupDetails extends GroupDetails {
  dashboards: DashboardObjectTO[];
}

export interface RealEstateFilterRequest {
  clientIds: number[];
  carbonFootprintStatuses: CarbonFootprintStatus[];
  includeTypes: GroupType[];
  dtype: string;
}

export enum CarbonFootprintStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export interface GroupInfo {
  id: number;
  name: string;
  type: GroupType;
}

export interface RealEstateFilterResult {
  group: GroupInfo;
  parent: GroupInfo;
}

export type RealEstateFilterResponse = RealEstateDetailsTO & {
  alarmChains?: RealEstateAlarmChain[];
  alarmChainsResponsibles?: Attendance[];
  parentGroup?: GroupInfo;
  users?: RealEstateUser[];
};

export type RealEstateUser = UserDisplayPropertiesTO & {
  name?: string;
};

export enum GroupsFilterType {
  GroupQueryFilterTO = 'GroupQueryFilterTO',
  RealEstateQueryFilterTO = 'RealEstateQueryFilterTO'
}

export interface GroupTicketsCount {
  alarm: number;
  building: number;
}
