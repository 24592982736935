import {Injectable} from '@angular/core';
import {apiVersion} from '../../../api-version.constant';
import {invalidateCache} from '../../../core/interceptors/caching.interceptor';

@Injectable({providedIn: 'root'})
export class RealEstateCacheHelper {
  static invalidateRealEstateOwnerships() {
    invalidateCache(/\/realEstateOwnership\/filter\{.*\}$/);
  }

  static invalidateGroups() {
    invalidateCache(/\/groups\/filter\{.*\}$/);
  }

  static invalidateRealEstateDetails(propertyId: number) {
    invalidateCache(apiVersion + 'realEstates/' + propertyId);
  }

  static invalidateAllRealEstateDetails() {
    invalidateCache(/\/realEstates\/\d+$/);
  }

  static invalidateRealEstateProperties() {
    invalidateCache(/\/realEstates\/filter\{.*\}$/);
  }
}
