export const ROOT = 'root';

export interface AlcNodeInfo {
  id?: string | number;
  name?: string;
  parentId?: string | number;
}

export interface AlcNode extends AlcNodeInfo {
  children?: AlcNode[];
  parent?: AlcNode;
  matchFilters?: boolean;
  matchIncludeChildrenFilters?: boolean;
  collapse?: boolean;
  depth?: number;
  depthPadding?: number;
  selected?: boolean;
  enableDragDrop?: boolean;
  draggable?: any;
  disabled?: boolean;
}

export interface AlcTreeFilter {
  id: string;
  value: ((propertyValue: any) => boolean | number | string) | number | boolean | string;
}

export interface AlcTreeFilters {
  'state.manualMode'?: number;
  'state.alarmState'?: number;
  dashboards?: number[];
}

export interface AlcIncludeChildrenTreeFilters {
  name?: string;
}

export interface TreeFilters {
  search?: boolean;
}
