import {apiVersion} from '../../../../api-version.constant';
import {invalidateCache} from '../../../../core/interceptors/caching.interceptor';

export class TicketListCacheHelper {
  static invalidateSchematicTicketList() {
    invalidateCache(apiVersion + 'tickets/loadData');
  }

  static invalidateTicketList() {
    TicketListCacheHelper.invalidateSchematicTicketList();
    invalidateCache(/\/ticketLists\/\d+\/loadData/);
  }
}
